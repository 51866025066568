var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import IconButton from 'shared/components/IconButton';
import PasswordStrengthBox from 'shared/components/PasswordStrengthBox';
import fieldToMuiProps from '../fieldToMuiProps';
var useStyles = makeStyles(function (theme) { return ({
    popper: {
        zIndex: theme.zIndex.modal,
    },
}); });
var PasswordField = function (props) {
    var confirmWith = props.confirmWith, showStrength = props.showStrength, rest = __rest(props, ["confirmWith", "showStrength"]);
    var muiProps = fieldToMuiProps(rest);
    if (confirmWith)
        showStrength = false;
    var classes = useStyles(props);
    var _a = useState(false), showPassword = _a[0], setShowPassword = _a[1];
    var _b = useState(false), isFocused = _b[0], setIsFocused = _b[1];
    var _c = useState(null), anchor = _c[0], setAnchor = _c[1];
    var toggleShowPassword = function () {
        setShowPassword(function (value) { return !value; });
    };
    var handleFocus = function (e) {
        setIsFocused(true);
    };
    var handleBlur = function () {
        setIsFocused(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(TextField, __assign({ InputProps: {
                    ref: setAnchor,
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { icon: showPassword ? 'eye-slash' : 'eye', "aria-label": "Toggle password visibility", tabIndex: -1, size: "small", onClick: toggleShowPassword }) })),
                } }, muiProps, { onFocus: handleFocus, onBlur: handleBlur })), showStrength && isFocused && (_jsx(Popper, { style: { width: anchor && anchor.clientWidth }, placement: 'bottom', open: true, anchorEl: anchor, role: "presentation", className: classes.popper, 
                // the following props are here just to make TS happy
                placeholder: "bottom", onPointerEnterCapture: null, onPointerLeaveCapture: null, children: _jsx(PasswordStrengthBox, { password: muiProps.value }) }))] }));
};
export default PasswordField;
