var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import { camelCase } from 'lodash';
import { useLayoutEffect } from 'react';
import LegalMenu from 'shared/components/LegalMenu';
import { useAppState } from 'shared/core/AppStateProvider';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
import { filterClasses } from 'shared/utils/filterClasses';
export var TOP_BANNER_ID = 'TopBanner';
var useStyles = makeStyles(function (_a) {
    var _b;
    var _c;
    var palette = _a.palette, breakpoints = _a.breakpoints, components = _a.components, pageMenu = _a.pageMenu;
    return deepmerge({
        pageContentRoot: {
            width: '100%',
            flex: 1,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            color: palette.text.primary,
            background: palette.background.default,
        },
        pageContentContainer: {
            flex: 1,
            overflow: 'hidden',
        },
        pageWithPadding: (pageMenu === null || pageMenu === void 0 ? void 0 : pageMenu.type) === 'top'
            ? {
                paddingTop: "calc(".concat((pageMenu === null || pageMenu === void 0 ? void 0 : pageMenu.appbarHeight) || 0, " + 1.6rem)"),
            }
            : (_b = {
                    paddingTop: '1.6rem'
                },
                _b[breakpoints.up('sm')] = {
                    paddingTop: '6.4rem',
                },
                _b),
        pagePublic: {},
        pageRestricted: {},
        pageLegalMenu: {},
    }, (_c = components.CxmPage) === null || _c === void 0 ? void 0 : _c.styleOverrides);
});
function Page(props) {
    var _a, _b, _c;
    var className = props.className, style = props.style, elementRef = props.elementRef, children = props.children, page = props.page, _d = props.hideLegalMenu, hideLegalMenu = _d === void 0 ? false : _d, _e = props.usePadding, usePadding = _e === void 0 ? true : _e, afterRender = props.afterRender, _f = props.footerNoMargin, footerNoMargin = _f === void 0 ? false : _f, _g = props.footer, _h = _g === void 0 ? {} : _g, _j = _h.legalMenu, legalMenu = _j === void 0 ? [] : _j;
    var _k = page || {}, ident = _k.ident, slug = _k.slug, footnote = _k.footnote, _l = _k.hasPageBackground, hasPageBackground = _l === void 0 ? true : _l;
    var user = useAppState().user;
    var classes = useStyles({
        classes: filterClasses(props.classes, [
            'pageContentContainer',
            'pageLegalMenu',
            'pagePublic',
            'pageRestricted',
            'pageContentRoot',
        ]),
    });
    useLayoutEffect(function () {
        afterRender && afterRender();
    }, [ident]);
    return (_jsxs("div", { id: camelCase("page-".concat(slug)), ref: elementRef, className: classNames(classes.pageContentRoot, (_a = {}, _a[classes.pageWithPadding] = user && usePadding, _a), (_b = {}, _b[classes.pagePublic] = !user, _b), (_c = {}, _c[classes.pageRestricted] = user, _c), className), style: !hasPageBackground ? __assign(__assign({}, style), { background: 'none' }) : style, children: [_jsx("div", { className: classes.pageContentContainer, children: children }), legalMenu && !hideLegalMenu && (_jsx(LegalMenu, { items: legalMenu, footnote: footnote, footerNoMargin: footerNoMargin, className: classes.pageLegalMenu }))] }));
}
export default function ThemedPage(props) {
    // get theme from page props
    var pageTheme = (props.page || {}).pageTheme;
    // page without theme - will use the default
    if (!pageTheme)
        return _jsx(Page, __assign({}, props));
    // themed page
    return (_jsx(ThemeProvider, { theme: CXMTheme.get(pageTheme), children: _jsx(Page, __assign({}, props)) }));
}
