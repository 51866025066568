import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/system';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import { useState } from 'react';
import { useApi } from 'shared/api/ApiContext';
import { MENU_HEIGHT } from 'shared/components/page/menu/pageMenu/PageMainMenu';
import { useAppState } from 'shared/core/AppStateProvider';
import FontAwesomeIcon from 'shared/elements/FontAwesomeIcon';
import useLayoutDimensions from 'shared/hooks/useLayoutDimensions';
import PopupFloatingCta from './PopupFloatingCta';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette, maxWidth = _a.maxWidth;
    return deepmerge({
        '@keyframes rotate': {
            '0%': {
                fontSize: 0,
            },
            '100%': {
                transform: 'rotate(360deg)',
                fontSize: '4rem',
            },
        },
        '@keyframes ctaShrink': {
            '25%': {
                transform: 'scale(1.1)',
            },
            '100%': {
                transform: 'scale(0)',
                display: 'none',
                opacity: 0,
            },
        },
        '@keyframes ctaEngorge': {
            '0%': {
                transform: 'scale(0)',
            },
            '100%': {
                transform: 'scale(1)',
            },
        },
        recommendationEngineCta: {
            zIndex: '4',
            cursor: 'pointer',
            backgroundColor: palette.primary.main,
            outline: '1px solid white',
            padding: '1.2rem',
            aspectRatio: 1,
            borderRadius: '50%',
            display: 'grid',
            placeItems: 'center',
            position: 'fixed',
            bottom: function (_a) {
                var mobile = _a.mobile, sectionBottomHeight = _a.sectionBottomHeight, isHealthWarningStatic = _a.isHealthWarningStatic;
                return (!isHealthWarningStatic ? 16 + sectionBottomHeight : mobile ? MENU_HEIGHT + 16 : 16);
            },
            right: document.documentElement.clientWidth > maxWidth
                ? (document.documentElement.clientWidth - maxWidth) / 2 + 16
                : 16,
            transition: '0.1s ease-in-out',
            animation: '$ctaEngorge 1.5s cubic-bezier(0.2, 0.8, 0.2, 1)',
            '&:active': {
                transform: 'scale(0.95)',
            },
        },
        recommendationEngineCtaDismissed: {
            animation: '$ctaShrink 0.3s linear forwards',
        },
        recommendationEngineIcon: {
            color: palette.primary.contrastText,
            fontSize: '3.5rem',
        },
        recommendationEngineIconCollapsed: {
            display: 'none',
            animation: '$rotate 0.3s ease-in-out reverse',
            fontSize: 0,
        },
    }, (_b = components.CxmFloatingCta) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
function FloatingCtas(props) {
    var _a = props.recommendation, id = _a.id, float_button_icon = _a.float_button_icon, float_box_title = _a.float_box_title, float_button_label = _a.float_button_label, float_box_copy = _a.float_box_copy, link = _a.link;
    var api = useApi();
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var sectionBottomHeight = useLayoutDimensions().sectionBottomHeight;
    var isHealthWarningStatic = useAppState().isHealthWarningStatic;
    var classes = useStyles({ mobile: isMobile, sectionBottomHeight: sectionBottomHeight, isHealthWarningStatic: isHealthWarningStatic });
    var _b = useState(true), isCtaDisplayed = _b[0], setIsCtaDisplayed = _b[1];
    var _c = useState(false), isPopupOpen = _c[0], setIsPopupOpen = _c[1];
    var openPopup = function () {
        if (isPopupOpen)
            return;
        setIsPopupOpen(true);
        id && api.trackRecommendationView(id);
    };
    var onPopupClose = function () {
        setIsPopupOpen(false);
        setIsCtaDisplayed(false);
        id && api.trackRecommendationClose(id);
    };
    var onCtaClick = function () {
        id && api.trackRecommendationClick(id);
        // Popup won't be dismissed otherwise
        setIsPopupOpen(false);
        setIsCtaDisplayed(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx("aside", { onClick: openPopup, className: classNames(classes.recommendationEngineCta, !isCtaDisplayed && classes.recommendationEngineCtaDismissed), children: _jsx(FontAwesomeIcon, { name: float_button_icon, solid: false, className: classes.recommendationEngineIcon }) }), openPopup && (_jsx(PopupFloatingCta, { open: isPopupOpen, onClose: onPopupClose, title: float_box_title, content: float_box_copy, ctaLabel: float_button_label, ctaLink: link, onCtaClick: onCtaClick }))] }));
}
export default FloatingCtas;
