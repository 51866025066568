var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { CommonUrls } from 'jticxm-constants';
import { useApi } from 'shared/api/ApiContext';
import { usePopup } from 'shared/core/PopupWrapper';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette;
    return deepmerge({
        link: {
            color: palette.text.primary,
            cursor: 'pointer',
            '& > a': {
                color: palette.text.primary,
            },
        },
    }, (_b = components.CxmLink) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
export default function Link(props) {
    var children = props.children, target = props.target, className = props.className, to = props.to, _a = props.redirect, redirect = _a === void 0 ? false : _a, rest = __rest(props, ["children", "target", "className", "to", "redirect"]);
    delete rest.classes; // avoid rendering unwanted prop
    var openPopup = usePopup().openPopup;
    var classes = useStyles(props);
    var classList = classNames(classes.link, className);
    var api = useApi();
    function onOtSettingsClick(event) {
        var _a, _b;
        event.stopPropagation();
        (_b = (_a = window['OneTrust']) === null || _a === void 0 ? void 0 : _a.ToggleInfoDisplay) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    // empty links - should only use "onClick"
    if (!to) {
        if (typeof rest.onClick !== 'function') {
            console.warn('Broken link: both properties "to" and "onClick" are undefined or mismatching');
        }
        return (_jsx("a", __assign({}, rest, { className: classList, children: children })));
    }
    // external links
    var isExternal = to.match(/^https?:\/\//);
    if (redirect || isExternal) {
        return (_jsx("a", __assign({}, rest, { className: classList, target: "_blank", href: to, rel: "noreferrer", onClick: isExternal ? function () { return api.trackOutboundLink(to); } : null, children: children })));
    }
    // mailto links
    if (to.match(/^mailto:/)) {
        return (_jsx("a", __assign({}, rest, { className: classList, href: to, rel: "noreferrer", children: children })));
    }
    // cookies policy link
    if (to === CommonUrls.COOKIES) {
        return (_jsx("a", __assign({}, rest, { className: classNames('ot-sdk-show-settings', classes.link, className), onClick: onOtSettingsClick, children: children })));
    }
    if (target === 'popup') {
        return (_jsx("a", __assign({}, rest, { className: classList, target: "_blank", rel: "noreferrer", onClick: function (e) {
                var _a;
                e.preventDefault();
                openPopup({ url: to });
                (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }, href: to, children: children })));
    }
    return (_jsx(RouterLink, __assign({ className: classNames(classes.link, className) }, rest, { to: to, target: target, children: children })));
}
