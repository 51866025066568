import { jsx as _jsx } from "react/jsx-runtime";
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
var useStyles = makeStyles(function (_a) {
    var _b;
    var breakpoints = _a.breakpoints;
    return ({
        root: (_b = {
                paddingLeft: '1.6rem',
                paddingRight: '1.6rem'
            },
            _b[breakpoints.up('sm')] = {
                paddingLeft: '2.4rem',
                paddingRight: '2.4rem',
            },
            _b),
        noPadding: {
            paddingLeft: '0',
            paddingRight: '0',
        },
        // for external styling purposes
        innerWrapper: {
            position: 'relative',
        },
    });
});
function ContentColumn(props) {
    var classes = useStyles(props);
    var children = props.children, id = props.id, className = props.className, noPadding = props.noPadding, _a = props.xs, xs = _a === void 0 ? 12 : _a, _b = props.sm, sm = _b === void 0 ? 12 : _b, _c = props.md, md = _c === void 0 ? 8 : _c, style = props.style;
    return (_jsx(Grid, { container: true, justifyContent: "center", id: id, style: style, className: classNames(classes.root, noPadding ? classes.noPadding : undefined, className), children: _jsx(Grid, { className: classes.innerWrapper, item: true, xs: xs, sm: sm, md: md, children: children }) }));
}
export default ContentColumn;
