var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from 'shared/components/Button';
import ButtonContainer from 'shared/components/ButtonContainer';
export default function ButtonGroup(props) {
    var buttons = props.buttons.filter(Boolean);
    return (_jsx(ButtonContainer, { component: props.component, className: props.className, children: buttons.map(function (button, index) {
            var _a = button, forcedVariant = _a.variant, rest = __rest(_a, ["variant"]);
            // get variant based on index if not already defined
            var variant = forcedVariant ? forcedVariant : getVariant(buttons, index);
            return _jsx(Button, __assign({}, rest, { variant: variant }), index);
        }) }));
}
export var getVariant = function (buttons, index) {
    if (index === 0)
        return 'contained';
    if (index + 1 === buttons.length)
        return 'text';
    return 'outlined';
};
