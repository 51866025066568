import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import IconButton from 'shared/components/IconButton';
import { usePopup } from 'shared/core/PopupWrapper';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components;
    return deepmerge({
        closeButton: {
            position: 'sticky',
            zIndex: 1,
            right: 0,
            top: 0,
            float: 'right',
            marginBottom: '-200%',
        },
    }, (_b = components.CxmPopupHeader) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
function PopupHeader(props) {
    var children = props.children, onCloseProp = props.onClose, className = props.className, hideClose = props.hideClose;
    var components = useTheme().components;
    var _a = ((components === null || components === void 0 ? void 0 : components.CxmPopupHeader) || {}).primary, primary = _a === void 0 ? true : _a;
    var classes = useStyles();
    var onCloseContext = usePopup().onClose;
    var onClose = onCloseProp || onCloseContext;
    return (_jsxs("div", { className: className, children: [onClose && !hideClose && (_jsx(IconButton, { icon: "times", "aria-label": "close", className: classes.closeButton, primary: primary, onClick: onClose, size: "large" })), children] }));
}
export default PopupHeader;
