var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import useResponsiveSizeImagePath from 'shared/hooks/useResponsiveSizeImagePath';
export default function ImageComponent(props) {
    var className = props.className, style = props.style, imagePath = props.image.path, size = props.size, responsiveSizes = props.responsiveSizes, _a = props.alt, alt = _a === void 0 ? 'Image' : _a, cover = props.cover, contain = props.contain, children = props.children, id = props.id, mediaRef = props.mediaRef, onAnimationEnd = props.onAnimationEnd, onClick = props.onClick, onLoad = props.onLoad;
    var path = useResponsiveSizeImagePath({ path: imagePath, size: size, responsiveSizes: responsiveSizes });
    if (!path)
        return null;
    var isBackground = cover || contain || children;
    if (isBackground) {
        var imageLoader = new Image();
        imageLoader.src = path;
        imageLoader.onload = function () {
            onLoad && onLoad();
        };
        return (_jsx("div", { className: className, id: id, ref: mediaRef, style: __assign({ backgroundImage: "url(\"".concat(path, "\")"), backgroundSize: cover ? 'cover' : contain ? 'contain' : null, backgroundPosition: 'center' }, style), onClick: onClick, children: children }));
    }
    else {
        return (_jsx("img", { className: classNames(className), id: id, ref: mediaRef, style: __assign(__assign({}, style), { pointerEvents: 'none' }), src: path, alt: alt, onClick: onClick, onLoad: onLoad, onAnimationEnd: onAnimationEnd }));
    }
}
