import { jsx as _jsx } from "react/jsx-runtime";
import { useAppState } from 'shared/core/AppStateProvider';
import formatNumber, { formatCurrency, formatShortNumber } from 'shared/utils/formatNumber';
export default function FormattedNumber(_a) {
    var number = _a.number, className = _a.className, isCurrency = _a.isCurrency, isShort = _a.isShort;
    var _b = useAppState().locale, locale = _b === void 0 ? 'de-DE' : _b;
    var formattedNumber = isShort
        ? formatShortNumber(number)
        : isCurrency
            ? formatCurrency(number, locale)
            : formatNumber(number, locale);
    return _jsx("span", { className: className, children: formattedNumber });
}
