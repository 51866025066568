var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Axios from 'axios';
import Cookies from 'js-cookie';
import { CommonUrls } from 'jticxm-constants';
import GTMManager from 'shared/utils/GTMManager';
import ApiConnector from './ApiConnector';
Axios.defaults.withCredentials = true;
var ClientApi = /** @class */ (function () {
    function ClientApi() {
        this._connector = new ApiConnector();
    }
    Object.defineProperty(ClientApi.prototype, "connector", {
        get: function () {
            return this._connector;
        },
        enumerable: false,
        configurable: true
    });
    ClientApi.prototype.resolveUrl = function (url, params) {
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            url = url.replace(":".concat(key), value);
        }
        return url;
    };
    ClientApi.prototype.acceptTerms = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_TERMS_ACCEPTANCE, { oid: oid });
        return this.connector.post(url);
    };
    ClientApi.prototype.gameItems = function (gameId) {
        var url = this.resolveUrl(CommonUrls.API_GAME_ITEMS, { gameId: gameId });
        return this.connector.get(url);
    };
    ClientApi.prototype.buyGameItem = function (gameId, gameItemId, quantity) {
        var url = this.resolveUrl(CommonUrls.API_GAME_ITEMS, { gameId: gameId });
        return this.connector.post(url, {
            gameItemId: gameItemId,
            quantity: quantity,
        });
    };
    ClientApi.prototype.createImage = function (promotionId, text) {
        return this.connector.post(CommonUrls.API_CREATE_IMAGE, {
            promotionId: promotionId,
            text: text,
            pathname: window.location.pathname,
        });
    };
    ClientApi.prototype.clearOpenAiSession = function () {
        return this.connector.delete(CommonUrls.API_CLEAR_OPENAI_SESSION);
    };
    ClientApi.prototype.likeOrUnlike = function (likeItem, oid) {
        var url = this.resolveUrl(CommonUrls.API_LIKE_ITEM, { likeItem: likeItem, oid: oid });
        return this.connector.post(url);
    };
    ClientApi.prototype.likeOrUnlikeMultiple = function (likeItem, oids) {
        return this.connector.post(CommonUrls.API_LIKE_ITEMS, { likeItem: likeItem, oids: oids });
    };
    ClientApi.prototype.packSampleQrCode = function (bundleId) {
        var url = this.resolveUrl(CommonUrls.API_PACK_SAMPLE_IN_STORE_ORDER, { oid: bundleId });
        return this.connector.post(url);
    };
    ClientApi.prototype.placePackSampleOrder = function (bundleId) {
        var url = this.resolveUrl(CommonUrls.API_PACK_SAMPLE_ORDER, { oid: bundleId });
        return this.connector.post(url);
    };
    ClientApi.prototype.packCode = function (data) {
        return this.connector.post(CommonUrls.API_REDEEM_PACK_CODE, data);
    };
    ClientApi.prototype.placeOrder = function (params) {
        return this.connector.post(CommonUrls.API_PLACE_ORDER, params);
    };
    ClientApi.prototype.checkParticipation = function (promotionId) {
        var url = this.resolveUrl(CommonUrls.API_PROMOTION_IS_PARTICIPATING, { promotionId: promotionId });
        return this.connector.post(url);
    };
    ClientApi.prototype.getAlbumCover = function (genre) {
        var url = this.resolveUrl(CommonUrls.API_GET_ALBUM_COVER, { genre: genre });
        return this.connector.get(url);
    };
    ClientApi.prototype.preprocessRewardOrder = function (id) {
        var url = this.resolveUrl(CommonUrls.API_PREPROCESS_REWARD_ORDER, { id: id });
        return this.connector.post(url);
    };
    ClientApi.prototype.placeRewardOrder = function (voucherId) {
        return this.connector.post(CommonUrls.API_PLACE_REWARD_ORDER, { voucherId: voucherId });
    };
    ClientApi.prototype.enterRaffle = function (promotionId, data) {
        var url = this.resolveUrl(CommonUrls.API_ENTER_RAFFLE, { promotionId: promotionId });
        return this.connector.post(url, data);
    };
    ClientApi.prototype.enterRaffleWithPackCode = function (promotionId, data) {
        var url = this.resolveUrl(CommonUrls.API_ENTER_RAFFLE_WITH_PACK_CODE, { promotionId: promotionId });
        return this.connector.post(url, data);
    };
    ClientApi.prototype.payForExtensionUsage = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_EXTENSION_PAY, { oid: oid });
        return this._connector.post(url);
    };
    ClientApi.prototype.promotionPoints = function (promotionId, target) {
        var url = this.resolveUrl(CommonUrls.API_PROMOTION_POINTS, { promotionId: promotionId });
        return this._connector.post(url, { target: target });
    };
    ClientApi.prototype.promotionUploads = function (promotionId, page, pageSize) {
        var url = this.resolveUrl(CommonUrls.API_PROMOTION_UPLOADS, { promotionId: promotionId });
        var urlParams = new URLSearchParams();
        // Append the 'page' and 'pageSize' parameters if they exist
        if (page !== undefined)
            urlParams.append('page', page.toString());
        if (pageSize !== undefined)
            urlParams.append('pageSize', pageSize.toString());
        // If there are any search params, append them to the URL
        if (urlParams.toString())
            url += "?".concat(urlParams.toString());
        return this.connector.get(url);
    };
    ClientApi.prototype.uploadFile = function (params) {
        var formData = new FormData();
        for (var key in params) {
            formData.append(key, params[key]);
        }
        formData.append('pathname', window.location.pathname);
        return this.connector.post(CommonUrls.API_UPLOAD, formData);
    };
    ClientApi.prototype.uploadVideoFile = function (file) {
        var formData = new FormData();
        formData.append('file', file);
        formData.append('pathname', window.location.pathname);
        return this.connector.post(CommonUrls.API_UPLOAD_VIDEO, formData);
    };
    ClientApi.prototype.updateUploadedFile = function (params) {
        var fileName = params.fileName, data = __rest(params, ["fileName"]);
        return this.connector.post(CommonUrls.API_UPLOAD + '/' + fileName, data);
    };
    ClientApi.prototype.downloadUploadedFile = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_UPLOADED_FILE_DOWNLOAD, { oid: oid });
        return this.connector.get(url);
    };
    ClientApi.prototype.playGambleGame = function (gameId) {
        return this.connector.post(CommonUrls.API_PLAY_GAMBLE_GAME, {
            gameId: gameId,
            pathname: window.location.pathname,
        });
    };
    ClientApi.prototype.convertToText = function (image) {
        return this.connector.post(CommonUrls.API_OCR_SERVICE, { image: image });
    };
    ClientApi.prototype.trackArticleReading = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_CONTENT_ARTICLE_READING, { oid: oid });
        return this.connector.post(url);
    };
    ClientApi.prototype.trackMediaCreation = function (data) {
        var pathname = window.location.pathname;
        return this.connector.post(CommonUrls.API_TRACK_MEDIA_CREATION, __assign(__assign({}, data), { pathname: pathname }));
    };
    ClientApi.prototype.trackVideoPlay = function (data) {
        var pathname = window.location.pathname;
        return this.connector.post(CommonUrls.API_TRACK_VIDEO_PLAY, __assign(__assign({}, data), { pathname: pathname }));
    };
    ClientApi.prototype.trackOutboundLink = function (url) {
        return this.connector.post(CommonUrls.API_TRACK_OUTBOUND_LINK, {
            url: url,
            pathname: window.location.pathname,
        });
    };
    ClientApi.prototype.trackStoryAdClick = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_STORY_AD_CLICK, { oid: oid });
        return this.connector.post(url);
    };
    ClientApi.prototype.trackStoryAdClose = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_STORY_AD_CLOSE, { oid: oid });
        return this.connector.post(url);
    };
    ClientApi.prototype.trackStoryAdView = function (oid) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_STORY_AD_VIEW, { oid: oid });
        return this.connector.post(url);
    };
    ClientApi.prototype.trackRecommendationView = function (id) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_RECOMMENDATION_VIEW, { id: id });
        return this.connector.post(url);
    };
    ClientApi.prototype.trackRecommendationClose = function (id) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_RECOMMENDATION_CLOSE, { id: id });
        return this.connector.post(url);
    };
    ClientApi.prototype.trackRecommendationClick = function (id) {
        var url = this.resolveUrl(CommonUrls.API_TRACK_RECOMMENDATION_CLICK, { id: id });
        return this.connector.post(url);
    };
    ClientApi.prototype.tradePromoQrCode = function () {
        return this.connector.post(CommonUrls.API_TRADE_PROMO_QR_CODE);
    };
    ClientApi.prototype.logout = function () {
        GTMManager.signOut();
        return this.connector.post(CommonUrls.API_LOGOUT);
    };
    ClientApi.prototype.addressLocalityAutocomplete = function (addressLocality) {
        return this.connector.post(CommonUrls.API_AUTOCOMPLETE_LOCALITY, { addressLocality: addressLocality });
    };
    ClientApi.prototype.getRoute = function (location) {
        var _this = this;
        if (window.hasOwnProperty('__INITIAL__DATA__')) {
            // for initial data we return a “mocked” request so all the listeners work like they would on an actual request
            var responseData_1 = window['__INITIAL__DATA__'];
            delete window['__INITIAL__DATA__'];
            return {
                request: new Promise(function (resolve) {
                    _this.connector.fromInitData(responseData_1);
                    resolve(responseData_1);
                }),
                cancel: function () { },
            };
        }
        else {
            var pathname = location.pathname, search = location.search;
            var headers = { Accept: 'application/json' };
            var lang = Cookies.get('lang');
            if (lang)
                headers['Accept-Language'] = lang;
            // const route = pathname
            var route = pathname === '/' ? '/~home~' : pathname;
            return this.connector.get("".concat(route).concat(search || ''), { headers: headers });
        }
    };
    ClientApi.prototype.saveABQuizData = function (data) {
        return this.connector.post(CommonUrls.API_AB_QUIZ, data);
    };
    ClientApi.prototype.saveQuizAnswer = function (oid, questionId, answer) {
        return this.connector.post(CommonUrls.API_QUIZ_ANSWER, {
            oid: oid,
            questionId: questionId,
            answer: answer,
        });
    };
    ClientApi.prototype.contact = function (data) {
        return this.connector.post(CommonUrls.API_CONTACT, data);
    };
    return ClientApi;
}());
export default ClientApi;
