import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
var useStyles = makeStyles(function () { return ({
    insideButton: {
        fontSize: '100%',
        marginLeft: '.3em',
    },
}); });
export default function FontAwesomeIcon(props) {
    var _a;
    var className = props.className, name = props.name, solid = props.solid, light = props.light, insideButton = props.insideButton, ref = props.ref, size = props.size, children = props.children, onClick = props.onClick;
    var classes = useStyles();
    return (_jsx(Icon, { ref: ref, className: classNames(className, "fa-".concat(name), (_a = {
                'fa-regular': !solid && !light,
                'fa-solid': solid,
                'fa-light': light && !solid
            },
            _a[classes.insideButton] = insideButton,
            _a)), fontSize: insideButton ? 'inherit' : size, onClick: onClick, children: children }));
}
