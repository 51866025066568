import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
var ApiContext = createContext(null);
var ApiProvider = function (props) {
    var navigate = useNavigate();
    useEffect(function () {
        props.api.connector.listenToApiResponse(navigateTo);
    }, []);
    var navigateTo = function (_a) {
        var href = _a.navigateTo;
        if (!href)
            return;
        var isReload = href.startsWith('$current');
        if (/^http/.test(href)) {
            return (window.location.href = href);
        }
        if (isReload) {
            var _b = /(\?.+?)?(#.*?)?$$/.exec(href) || [], search = _b[1], hash = _b[2];
            href = window.location.pathname + (search || window.location.search) + (hash || window.location.hash);
        }
        navigate(href);
    };
    return _jsx(ApiContext.Provider, { value: props.api, children: props.children });
};
var useApi = function () {
    return useContext(ApiContext);
};
export { ApiProvider, useApi };
