import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import deepmerge from '@mui/utils/deepmerge';
import classNames from 'classnames';
import { forwardRef } from 'react';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components;
    return deepmerge({
        root: {
            flex: 1,
            padding: '3.6rem 2.4rem',
            margin: '0 auto',
            maxWidth: '40rem',
            width: '100%',
            boxSizing: 'border-box',
            '& > h1, > h2, > h3, > h4, > h5, > h6': {
                marginRight: function (_a) {
                    var hasHeaderMedia = _a.hasHeaderMedia, hasCloseButton = _a.hasCloseButton;
                    return !hasHeaderMedia && hasCloseButton ? '2.8rem' : null;
                },
            },
        },
    }, (_b = components.CxmPopupContent) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
var PopupContent = forwardRef(function (props, ref) {
    var children = props.children, className = props.className, _a = props.hasCloseButton, hasCloseButton = _a === void 0 ? true : _a, hasHeaderMedia = props.hasHeaderMedia, style = props.style;
    var classes = useStyles({ hasHeaderMedia: hasHeaderMedia, hasCloseButton: hasCloseButton });
    return (_jsx("div", { ref: ref, className: classNames(classes.root, className), style: style, children: children }));
});
export default PopupContent;
