import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
var useStyles = makeStyles(function (_a) {
    var _b, _c;
    var _d;
    var components = _a.components, breakpoints = _a.breakpoints, palette = _a.palette;
    return deepmerge({
        root: (_b = {
                // position: 'fixed',
                // bottom: 0,
                width: '100%',
                zIndex: function (_a) {
                    var hwStatic = _a.hwStatic;
                    return !hwStatic && 3;
                },
                paddingBottom: function (_a) {
                    var hasHw = _a.hasHw, hasPageMenu = _a.hasPageMenu, hwStatic = _a.hwStatic, user = _a.user;
                    if (!user && !hasHw)
                        return 0;
                    if (user && !hasPageMenu)
                        return 0;
                    if (user && hwStatic)
                        return 60;
                    return null;
                }
            },
            _b[breakpoints.up('sm')] = {
                paddingBottom: function (_a) {
                    var hasHw = _a.hasHw, hasPageMenu = _a.hasPageMenu, hwStatic = _a.hwStatic, user = _a.user;
                    if (!user && !hasHw)
                        return 0;
                    if (user && !hasPageMenu)
                        return 0;
                    if (user && hwStatic)
                        return 80;
                    return null;
                },
            },
            _b),
        warning: (_c = {
                boxSizing: 'border-box',
                minHeight: '10vh',
                padding: '.8rem 1rem',
                // position: 'relative',
                // zIndex: 1400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: palette.common.black,
                backgroundColor: palette.common.white,
                textAlign: 'center',
                fontSize: '1.2rem',
                '&:empty': {
                    display: 'none',
                }
            },
            _c[breakpoints.up('xs')] = {
                fontSize: '1.1rem',
                padding: '.8rem 1.5rem',
            },
            _c[breakpoints.up('sm')] = {
                fontSize: '1.4rem',
            },
            _c[breakpoints.up('md')] = {
                fontSize: '1.6rem',
                padding: '.8rem 2rem',
            },
            _c),
        captcha: {
            display: 'block',
            textAlign: 'center',
            padding: 4,
            color: '#666',
            backgroundColor: '#f7f7f7',
            fontSize: 8,
            lineHeight: 1.4,
            letterSpacing: '0.04em',
            hyphens: 'auto',
            // wordBreak: 'break-word',
            '& > a': {
                textDecoration: 'none',
                color: '#09f',
            },
        },
    }, 
    // we need this to avoid ts circular references error
    (_d = components.CxmPageFooter) === null || _d === void 0 ? void 0 : _d.styleOverrides);
});
export default function PageFooter(props) {
    var _a = useAppState(), user = _a.user, isHealthWarningStatic = _a.isHealthWarningStatic;
    var classes = useStyles({
        classes: props.classes,
        hasHw: !!props.healthWarning,
        hasPageMenu: props.hasPageMenu,
        hwStatic: isHealthWarningStatic,
        user: !!user,
    });
    return (_jsxs("footer", { className: classes.root, children: [_jsxs(Typography, { className: classes.captcha, component: "small", variant: "caption", children: ['This site is protected by reCAPTCHA. Google ', _jsx("a", { href: "https://policies.google.com/privacy", children: "Privacy Policy" }), ' and ', _jsx("a", { href: "https://policies.google.com/terms", children: "Terms" }), ' apply.'] }), _jsx(Typography, { id: "healthWarning", className: classes.warning, variant: "subtitle2", noMargin: true, children: props.healthWarning })] }));
}
