var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { createContext, useContext, useEffect } from 'react';
import { useApi } from 'shared/api/ApiContext';
import IconButton from 'shared/components/IconButton';
import Typography from 'shared/components/Typography';
import FontAwesomeIcon from 'shared/elements/FontAwesomeIcon';
var useStyles = makeStyles(function (_a) {
    var _b, _c;
    var _d;
    var breakpoints = _a.breakpoints, components = _a.components, palette = _a.palette;
    return deepmerge({
        containerRoot: (_b = {
                //  bottom: '10.5vh !important',
                maxWidth: 'none !important',
                zIndex: '1600 !important',
                top: '8px !important',
                '&>.MuiCollapse-root>.MuiCollapse-wrapper': {
                    paddingTop: '0 !important',
                }
            },
            _b[breakpoints.down('sm')] = {
                top: '0 !important',
            },
            _b),
        iconWithPadding: {
            paddingRight: '8px',
        },
        toastBase: (_c = {
                boxSizing: 'border-box',
                maxWidth: '100%'
            },
            _c[breakpoints.up('sm')] = {
                width: '44rem',
            },
            // this is the message container
            _c['& > div:nth-child(1)'] = {
                flex: '0 1 calc(100% - 4.8rem)',
            },
            // this is the action
            _c['& > div:nth-child(2)'] = {
                paddingLeft: 0,
            },
            _c),
        closeButton: {
            color: 'inherit',
        },
        info: {
            color: "".concat(palette.info.main, " !important"),
            backgroundColor: "".concat(palette.info.light, " !important"),
        },
        error: {
            color: "".concat(palette.error.main, " !important"),
            backgroundColor: "".concat(palette.error.light, " !important"),
        },
        success: {
            color: "".concat(palette.success.main, " !important"),
            backgroundColor: "".concat(palette.success.light, " !important"),
        },
        warning: {
            color: "".concat(palette.warning.main, " !important"),
            backgroundColor: "".concat(palette.warning.light, " !important"),
        },
        text: {
            color: 'inherit',
        },
        actionButton: {
            color: 'inherit',
            fontSize: '1.6rem',
        },
    }, (_d = components.CxmToastMessages) === null || _d === void 0 ? void 0 : _d.styleOverrides);
});
var ToastContext = createContext(function (toast) {
    console.log('ToastMessages not initialised yet');
    return function () { };
});
export var useToast = function () { return useContext(ToastContext); };
function ToastMessages(_a) {
    var _this = this;
    var children = _a.children;
    var classes = useStyles();
    var _b = useSnackbar(), enqueueSnackbar = _b.enqueueSnackbar, closeSnackbar = _b.closeSnackbar;
    var api = useApi();
    var CloseAction = function (_a) {
        var actionKey = _a.actionKey;
        return (_jsx(IconButton, { icon: "times", size: "small", "aria-label": "close", className: classes.closeButton, onClick: function () {
                closeSnackbar(actionKey);
            } }));
    };
    var Actions = function (props) {
        var action = props.action, actionLabel = props.actionLabel, actionKey = props.actionKey;
        return (_jsxs(_Fragment, { children: [action && (_jsx(Button, { className: classes.actionButton, variant: "text", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        var request;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    request = api.connector.post(action).request;
                                    return [4 /*yield*/, request];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }, children: actionLabel })), _jsx(CloseAction, { actionKey: actionKey })] }));
    };
    var showToast = function (toast) {
        var message = toast.message, _a = toast.variant, variant = _a === void 0 ? 'success' : _a, disabled = toast.disabled, _b = toast.isPersistent, isPersistent = _b === void 0 ? false : _b, _c = toast.autoHideDuration, autoHideDuration = _c === void 0 ? -1 : _c;
        // skip when disabled
        if (disabled)
            return;
        var key = enqueueSnackbar(_jsx(Typography, { variant: "body2", className: classes.text, noMargin: true, children: message }), {
            variant: variant,
            action: function (actionKey) { return _jsx(Actions, __assign({}, toast, { actionKey: actionKey })); },
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            autoHideDuration: isPersistent ? undefined : autoHideDuration > 0 ? autoHideDuration * 1000 : 5000,
            persist: isPersistent,
        });
        return function () { return closeSnackbar(key); };
    };
    var onApiResponse = function (_a) {
        var alert = _a.alert;
        if (!alert)
            return;
        return showToast(alert);
    };
    useEffect(function () {
        api.connector.listenToApiResponse(onApiResponse);
    }, []);
    return _jsx(ToastContext.Provider, { value: showToast, children: children });
}
export default function ToastMessagesWrapper(props) {
    var classes = useStyles();
    return (_jsx(SnackbarProvider, { maxSnack: 3, classes: {
            containerRoot: classes.containerRoot,
            variantInfo: classNames(classes.toastBase, classes.info),
            variantError: classNames(classes.toastBase, classes.error),
            variantSuccess: classNames(classes.toastBase, classes.success),
            variantWarning: classNames(classes.toastBase, classes.warning),
        }, iconVariant: {
            success: _jsx(FontAwesomeIcon, { name: "check-circle", className: classes.iconWithPadding, solid: true }),
            error: _jsx(FontAwesomeIcon, { name: "circle-exclamation", className: classes.iconWithPadding, solid: true }),
            warning: _jsx(FontAwesomeIcon, { name: "triangle-exclamation", className: classes.iconWithPadding, solid: true }),
            info: _jsx(FontAwesomeIcon, { name: "info-circle", className: classes.iconWithPadding, solid: true }),
        }, children: _jsx(ToastMessages, { children: props.children }) }));
}
