var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Typography from 'shared/components/Typography';
var useStyles = makeStyles(function (_a) {
    var pointsBadge = _a.pointsBadge, pointsBadgeNegative = _a.pointsBadgeNegative;
    return ({
        pointsBadge: __assign({ display: 'inline', marginLeft: '0.7em' }, pointsBadge),
        pointsBadgeNegative: pointsBadgeNegative,
    });
});
export default function PointsBadge(props) {
    var _a;
    var className = props.className, points = props.points;
    var classes = useStyles();
    if (!points)
        return null;
    return (_jsxs(Typography, { variant: "overline", component: "span", className: classNames(classes.pointsBadge, className, (_a = {},
            _a[classes.pointsBadgeNegative] = points < 0,
            _a)), children: [points > 0 && '+', points] }));
}
