var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Axios from 'axios';
import Captcha from './Captcha';
import EventChannel from './EventChannel';
import SocketChannel from './SocketChannel';
Axios.defaults.withCredentials = true;
var ApiConnector = /** @class */ (function () {
    function ApiConnector() {
        this._responseChannel = new EventChannel();
        this._socketChannel = new SocketChannel();
    }
    ApiConnector.prototype.setupCaptcha = function (captchaId) {
        this._createCaptchaToken = Captcha.setup(captchaId);
    };
    ApiConnector.prototype.listenToApiResponse = function (listener) {
        return this._responseChannel.subscribe(listener);
    };
    ApiConnector.prototype.listenToSocket = function (listener) {
        return this._socketChannel.subscribe(listener);
    };
    ApiConnector.prototype.createApiCall = function (options) {
        // creating cancel token and appending it to the axios config
        var source = Axios.CancelToken.source();
        var config = __assign(__assign({}, options.config), { cancelToken: source.token });
        return {
            request: this._createRequest(__assign(__assign({}, options), { config: config })),
            cancel: function () {
                source.cancel('cancelled');
            },
            url: config.url,
        };
    };
    ApiConnector.prototype._createRequest = function (_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var captchaToken, err_1, responseData, error_1, responseData;
            var _c;
            var url = _b.url, method = _b.method, data = _b.data, config = _b.config;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(method === 'post' && this._createCaptchaToken)) return [3 /*break*/, 4];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._createCaptchaToken(url.toString().replace(/[^A-Za-z/_]/g, ''))];
                    case 2:
                        captchaToken = _d.sent();
                        if (!config.headers)
                            config.headers = {};
                        config.headers['x-captcha-token'] = captchaToken;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _d.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4:
                        _d.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, Axios.request(__assign({ method: method, url: url, data: data }, config))];
                    case 5:
                        responseData = _d.sent();
                        responseData = responseData.data;
                        this._dispatchToChannels(responseData);
                        return [2 /*return*/, responseData];
                    case 6:
                        error_1 = _d.sent();
                        if (Axios.isCancel(error_1)) {
                            // since this request is cancelled
                            // we don't want it to resolve/reject anymore
                            return [2 /*return*/, new Promise(function () { })];
                        }
                        responseData = (_c = error_1.response) === null || _c === void 0 ? void 0 : _c.data;
                        if (responseData)
                            this._dispatchToChannels(responseData);
                        throw error_1;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApiConnector.prototype._dispatchToChannels = function (data) {
        var reconnectSocket = data.reconnectSocket;
        this._responseChannel.dispatch(data);
        if (reconnectSocket)
            this._socketChannel.reconnect();
    };
    ApiConnector.prototype.get = function (url, config, data) {
        return this.createApiCall({ url: url, method: 'get', config: config, data: data });
    };
    ApiConnector.prototype.delete = function (url, config) {
        return this.createApiCall({ url: url, method: 'delete', config: config });
    };
    ApiConnector.prototype.post = function (url, data, config) {
        return this.createApiCall({ url: url, method: 'post', config: config, data: data });
    };
    ApiConnector.prototype.put = function (url, data, config) {
        return this.createApiCall({ url: url, method: 'put', config: config, data: data });
    };
    ApiConnector.prototype.patch = function (url, data, config) {
        return this.createApiCall({ url: url, method: 'patch', config: config, data: data });
    };
    ApiConnector.prototype.fromInitData = function (data) {
        var _this = this;
        // since useEffects run bottom up, the listeners aren't bound yet; we need to run a tick later so
        // requestAnimationFrame it is
        requestAnimationFrame(function () {
            _this._dispatchToChannels(data);
        });
        return data;
    };
    return ApiConnector;
}());
export default ApiConnector;
