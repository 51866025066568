import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/system';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import Button from 'shared/components/Button';
import Typography from 'shared/components/Typography';
import { MENU_HEIGHT } from 'shared/components/page/menu/pageMenu/PageMainMenu';
import Popup from 'shared/components/popup/Popup';
import PopupBox from 'shared/components/popup/PopupBox';
import PopupContent from 'shared/components/popup/PopupContent';
import PopupHeader from 'shared/components/popup/PopupHeader';
import { useAppState } from 'shared/core/AppStateProvider';
import RichText from 'shared/elements/RichText';
import useLayoutDimensions from 'shared/hooks/useLayoutDimensions';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette, maxWidth = _a.maxWidth;
    return deepmerge({
        '@keyframes clockAppear': {
            '0%': {
                clipPath: 'inset(0)',
            },
            '100%': {
                clipPath: 'inset(0 0 100% 0)',
            },
        },
        root: {
            zIndex: '3',
        },
        box: {
            position: 'absolute',
            overflow: 'hidden',
            height: 56,
            width: 56,
            borderRadius: '50%',
            bottom: function (_a) {
                var mobile = _a.mobile, sectionBottomHeight = _a.sectionBottomHeight, isHealthWarningStatic = _a.isHealthWarningStatic;
                return !isHealthWarningStatic ? "calc(".concat(sectionBottomHeight, "px - 60px)") : mobile ? MENU_HEIGHT + 16 : 16;
            },
            right: document.documentElement.clientWidth > maxWidth
                ? (document.documentElement.clientWidth - maxWidth) / 2 + 16
                : 16, // With the subtraction you get space left and right of the content column
            // Split in half to get space to the left and right.
            transform: 'scale(0)',
            transition: '0.5s transform ease',
        },
        boxExtended: {
            height: 'auto',
            maxHeight: 'calc(60% - 3.2rem)',
            width: 'calc(100% - 3.2rem)',
            borderRadius: '1.2rem',
            bottom: function (_a) {
                var mobile = _a.mobile, sectionBottomHeight = _a.sectionBottomHeight, isHealthWarningStatic = _a.isHealthWarningStatic;
                return !isHealthWarningStatic
                    ? "calc(".concat(sectionBottomHeight, "px + 16px)")
                    : mobile
                        ? MENU_HEIGHT + 16 + 60 + 16 // 60 is the size of the CTA, 2nd 16 is extra spacing on top,
                        : 16 + 60 + 16;
            },
            right: document.documentElement.clientWidth > maxWidth
                ? (document.documentElement.clientWidth - maxWidth) / 2 + 16
                : 16,
            transform: 'scale(1)',
        },
        boxIsClosing: {
            transform: 'scale(0)',
        },
        boxContentAnimation: {
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            animation: '$clockAppear 1s ease-in-out forwards',
            animationDelay: '1s',
            background: palette.background.default,
        },
        header: {},
        media: {},
        content: {
            padding: '2.4rem',
        },
        title: {
            textTransform: 'none',
            marginBottom: '1.6rem',
        },
        copy: {},
        cta: {
            width: '100%',
        },
    }, (_b = components.CxmPopupFloatingCta) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
export default function PopupFloatingCta(props) {
    var sectionBottomHeight = useLayoutDimensions().sectionBottomHeight;
    var isHealthWarningStatic = useAppState().isHealthWarningStatic;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var classes = useStyles({ mobile: isMobile, sectionBottomHeight: sectionBottomHeight, isHealthWarningStatic: isHealthWarningStatic });
    var content = props.content, ctaLink = props.ctaLink, ctaLabel = props.ctaLabel, hideClose = props.hideClose, id = props.id, open = props.open, onClose = props.onClose, title = props.title, onCtaClick = props.onCtaClick;
    return (_jsx(Popup, { id: id, open: open, onClose: onClose, className: classes === null || classes === void 0 ? void 0 : classes.root, keepMounted: true, children: _jsxs(PopupBox, { className: classNames(classes === null || classes === void 0 ? void 0 : classes.box, open ? classes === null || classes === void 0 ? void 0 : classes.boxExtended : classes === null || classes === void 0 ? void 0 : classes.boxIsClosing), children: [_jsx("div", { className: open && classes.boxContentAnimation }), _jsx(PopupHeader, { onClose: onClose, hideClose: hideClose, className: classes === null || classes === void 0 ? void 0 : classes.header }), _jsxs(PopupContent, { className: classes === null || classes === void 0 ? void 0 : classes.content, hasCloseButton: !hideClose, hasHeaderMedia: false, children: [_jsx(Typography, { variant: "h2", className: classes === null || classes === void 0 ? void 0 : classes.title, children: title }), _jsx(Typography, { className: classes === null || classes === void 0 ? void 0 : classes.copy, children: _jsx(RichText, { content: content }) }), ctaLink && ctaLabel && (_jsx(Button, { label: ctaLabel, to: ctaLink, variant: "contained", className: classes.cta, onClick: onCtaClick }))] })] }) }));
}
