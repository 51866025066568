var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
import RichText from 'shared/elements/RichText';
import getPasswordStrength from 'shared/utils/getPasswordStrength';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette;
    return deepmerge({
        root: {
            width: '100%',
            padding: '2rem',
            boxSizing: 'border-box',
            backgroundColor: palette.background.default,
            '& > div > *': {
                marginTop: 0,
                fontSize: '1.4rem !important',
            },
        },
        bar: {
            marginTop: '1rem',
            backgroundColor: 'grey',
            width: '100%',
            height: '0.5rem',
        },
        barFill: {
            backgroundColor: 'rgba(255,255,255,0.76)',
            height: '100%',
        },
        caption: {
            marginTop: '0.5rem',
            width: '100%',
        },
    }, (_b = components.CxmPasswordStrengthBox) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
function PasswordStrengthBox(props) {
    var _a;
    var classes = useStyles(props);
    var className = props.className, password = props.password;
    var _b = useAppState(), settings = _b.settings, labels = _b.labels;
    if (!password)
        return null;
    var _c = getPasswordStrength(password, (_a = settings === null || settings === void 0 ? void 0 : settings.technical) === null || _a === void 0 ? void 0 : _a.passwordPatterns), score = _c.score, caption = _c.caption, color = _c.color;
    return (_jsxs(Paper, { elevation: 5, square: true, className: classNames(classes.root, className), children: [_jsx(Typography, { component: "div", variant: "body2", noMargin: true, children: _jsx(RichText, { content: labels.PASSWORD_STRENGTH_TEXT }) }), _jsx("div", { className: classes.bar, style: { backgroundColor: color }, children: _jsx("div", { className: classes.barFill, style: { marginLeft: "".concat(score * 100, "%") } }) }), _jsx(Typography, { component: "div", variant: "body2", className: classes.caption, noMargin: true, children: labels[caption] })] }));
}
export default function ThemedPasswordStrengthBox(props) {
    // get theme from default options
    var passwordStrengthBoxTheme = useTheme().passwordStrengthBoxTheme;
    // component without theme - will inherit the theme
    if (!passwordStrengthBoxTheme)
        return _jsx(PasswordStrengthBox, __assign({}, props));
    // themed component
    var theme = CXMTheme.get(passwordStrengthBoxTheme);
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(PasswordStrengthBox, __assign({}, props)) }));
}
