import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useEffect } from 'react';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import useCounter from 'shared/hooks/useCounter';
import { filterClasses } from 'shared/utils/filterClasses';
var useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    timeDivision: {
        padding: '0 1.2rem',
        textAlign: 'center',
    },
    timeUnit: {},
    timeValue: {
        fontFamily: 'Courier, monospace',
    },
});
export default function Timer(props) {
    var onTimerEnd = props.onTimerEnd;
    var classes = useStyles(props);
    var labels = useAppState().labels;
    var divisionClasses = filterClasses(classes, [
        'timeDivision',
        'timeUnit',
        'timeValue',
    ]);
    var timeLeft = useCounter({ initialValue: props.deadline.getTime() })[0];
    var deadline = getDecompounded(new Date(timeLeft));
    useEffect(function () {
        if (!onTimerEnd)
            return;
        var hours = deadline.hours, minutes = deadline.minutes, seconds = deadline.seconds;
        if (hours + minutes + seconds === 0)
            onTimerEnd();
    }, [deadline]);
    return (_jsx("div", { className: classes.root, children: ['hours', 'minutes', 'seconds']
            .filter(function (unitKey) { return typeof deadline[unitKey] === 'number'; })
            .map(function (unitKey) { return (_jsx(TimeDivision, { classes: divisionClasses, value: deadline[unitKey], unit: labels[unitKey.toUpperCase()] }, unitKey)); }) }));
}
function TimeDivision(props) {
    var classes = props.classes, value = props.value, unit = props.unit;
    return (_jsxs("div", { className: classes.timeDivision, children: [_jsx(Typography, { variant: "h3", noMargin: true, className: classes.timeValue, children: String(value).padStart(2, '0') }), _jsx(Typography, { variant: "overline", noMargin: true, className: classes.timeUnit, children: unit })] }));
}
function getDecompounded(date) {
    var hours = 0;
    var minutes = 0;
    var seconds = 0;
    var end = moment(date);
    var now = moment();
    if (now.isAfter(end))
        return { hours: hours, minutes: minutes, seconds: seconds };
    hours = end.diff(now, 'hours');
    minutes = end.diff(now, 'minutes') - hours * 60;
    seconds = end.diff(now, 'seconds') - hours * 60 * 60 - minutes * 60;
    return { hours: hours, minutes: minutes, seconds: seconds };
}
