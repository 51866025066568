import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import Link from 'shared/components/Link';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import ContentColumn from 'shared/elements/ContentColumn';
var useStyles = makeStyles(function (_a) {
    var _b;
    var _c;
    var components = _a.components, breakpoints = _a.breakpoints, palette = _a.palette;
    return deepmerge({
        legalMenu: (_b = {
                padding: '2rem 0',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            },
            _b[breakpoints.up('sm')] = {
                padding: '5rem 0',
                paddingBottom: function (_a) {
                    var isHealthWarningStatic = _a.isHealthWarningStatic;
                    return !isHealthWarningStatic && '11rem';
                },
            },
            _b['& > a'] = {
                padding: '0 .5em',
                textDecoration: 'none',
                color: palette.common.white,
            },
            _b),
        footerMargin: {
            margin: '4rem 0 0',
        },
        footnote: {
            color: palette.common.white,
            textAlign: 'center',
            fontSize: '1rem',
            marginTop: '1.6rem',
        },
    }, (_c = components.CxmLegalMenu) === null || _c === void 0 ? void 0 : _c.styleOverrides);
});
export default function LegalMenu(props) {
    var _a;
    var items = props.items, className = props.className, footnote = props.footnote, footerNoMargin = props.footerNoMargin;
    var isHealthWarningStatic = useAppState().isHealthWarningStatic;
    var classes = useStyles({ isHealthWarningStatic: isHealthWarningStatic });
    var components = useTheme().components;
    var _b = (components.CxmLegalMenu || {}).openLinksInNewTab, openLinksInNewTab = _b === void 0 ? false : _b;
    return (_jsxs(Typography, { id: "LegalMenu", component: "nav", variant: "body2", noMargin: true, className: classNames(className, classes.legalMenu, (_a = {}, _a[classes.footerMargin] = !footerNoMargin, _a)), children: [items.map(function (item, i) { return (_jsx(Link, { to: item.to, redirect: item.isRedirect, target: openLinksInNewTab ? '_blank' : null, children: item.name || '⚠️ [menu name]' }, i)); }), footnote && (_jsx(ContentColumn, { children: _jsx(Typography, { className: classes.footnote, variant: "body2", noMargin: true, children: footnote }) }))] }));
}
