import { jsx as _jsx } from "react/jsx-runtime";
import makeStyles from '@mui/styles/makeStyles';
import FontAwesomeIcon from 'shared/elements/FontAwesomeIcon';
var useStyles = makeStyles(function (_a) {
    var palette = _a.palette;
    return ({
        iconWrapper: {
            backgroundColor: palette.background.default,
            borderRadius: '50%',
            width: 16,
            height: 16,
        },
        icon: {
            color: palette.error.main,
            marginLeft: -1,
            marginTop: -1,
            fontSize: 18,
        },
    });
});
var ErrorIcon = function (props) {
    var classes = useStyles(props);
    return (_jsx("span", { className: classes.iconWrapper, children: _jsx(FontAwesomeIcon, { name: "exclamation-circle", className: classes.icon, light: props.iconStyle === 'light', solid: props.iconStyle === 'solid' }) }));
};
export default ErrorIcon;
