import { jsx as _jsx } from "react/jsx-runtime";
import { animated } from 'react-spring';
export default function StoryAdsProgress(_a) {
    var ads = _a.ads, adProgress = _a.adProgress, currentAdIndex = _a.currentAdIndex, classes = _a.classes;
    return (_jsx("div", { className: classes.root, children: ads.map(function (_, index) {
            var isActive = currentAdIndex === index;
            var barStateClassName = isActive ? classes.barActive : classes.barInactive;
            return (_jsx("div", { className: "".concat(classes.value, " ").concat(barStateClassName), children: isActive ? (_jsx(animated.div, { style: { width: adProgress.to(function (val) { return "".concat(val, "%"); }), height: '100%' }, className: classes.bar })) : (_jsx("div", { style: { width: currentAdIndex > index ? '100%' : '0%', height: '100%' }, className: classes.bar })) }, index));
        }) }));
}
