var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { throttle } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from 'shared/api/ApiContext';
import Typography from 'shared/components/Typography';
import fieldToMuiProps from './fieldToMuiProps';
var useStyles = makeStyles(function (_a) {
    var palette = _a.palette;
    return ({
        paper: {
            backgroundColor: palette.background.default,
        },
        endAdornment: {
            display: 'none',
        },
    });
});
var AutocompletedLocality = function (props) {
    var api = useApi();
    var muiProps = fieldToMuiProps(props);
    var value = useState(muiProps.value)[0];
    var _a = useState(''), inputValue = _a[0], setInputValue = _a[1];
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var cancelRef = useRef(null);
    var classes = useStyles(props);
    var fetch = useMemo(function () {
        return throttle(function (locality) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, request, cancel_1, result, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        if (locality === '') {
                            setOptions([]);
                            return [2 /*return*/, undefined];
                        }
                        _a = api.addressLocalityAutocomplete(locality), request = _a.request, cancel_1 = _a.cancel;
                        cancelRef.current = function () {
                            cancel_1();
                        };
                        return [4 /*yield*/, request];
                    case 1:
                        result = _b.sent();
                        cancelRef.current = null;
                        setOptions(result);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); }, 800);
    }, []);
    var getOptionLabel = function (option) {
        if (typeof option === 'string')
            return option;
        if (props.field.name === 'postalCode')
            return option.zip;
        return option.city;
    };
    useEffect(function () {
        if (cancelRef.current) {
            cancelRef.current();
            cancelRef.current = null;
        }
        fetch(inputValue);
    }, [value, inputValue, fetch]);
    return (_jsx(Autocomplete, { options: options, id: "".concat(props.field.name, "-autocomplete"), freeSolo: true, filterOptions: function (x) { return x; }, filterSelectedOptions: true, includeInputInList: true, autoComplete: true, classes: classes, getOptionLabel: getOptionLabel, onInputChange: function (event, newInputValue) {
            setInputValue(newInputValue);
        }, value: value, onChange: function (event, newValue) {
            // setOptions(newValue ? [newValue, ...options] : options)
            var zip, city;
            if (!newValue) {
                city = '';
                zip = '';
            }
            else {
                city = newValue.city;
                zip = newValue.zip;
            }
            props.form.setFieldValue('addressLocality', city);
            props.form.setFieldValue('postalCode', zip);
            // setValue(city)
            // props.form.setFieldValue(props.field.name, city)
            // props.form.setFieldValue('postalCode', zip)
        }, renderInput: function (params) { return _jsx(MuiTextField, __assign({}, muiProps, params)); }, renderOption: function (props, option) { return (_jsxs(Typography, __assign({}, props, { noMargin: true, children: [option.city, " | ", option.zip] }))); } }));
};
export default AutocompletedLocality;
