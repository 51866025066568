var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ButtonGroup from 'shared/components/ButtonGroup';
import { useAppState } from 'shared/core/AppStateProvider';
import getDeepModuleLink from 'shared/utils/getDeepModuleLink';
import getTermsLink from 'shared/utils/getTermsLink';
import ContentModuleWrapper from './ContentModuleWrapper';
import useContentModuleStyles from './useContentModuleStyles';
export default function ContentModuleButton(props) {
    var labels = useAppState().labels;
    var classes = useContentModuleStyles(props);
    return (_jsx(ContentModuleWrapper, { id: props.id, className: classes.contentModuleWrapper, innerClassName: [classes.contentModuleText, props.className].join(' '), children: _jsx(ButtonGroup, { buttons: [getButtonObject(__assign(__assign({}, props), { labels: labels }))] }) }));
}
export function getButtonObject(props) {
    var _a;
    var buttonType = props.buttonType, buttonLabel = props.buttonLabel, buttonLink = props.buttonLink, buttonExternalLink = props.buttonExternalLink, buttonExternalLinkSkipNewTab = props.buttonExternalLinkSkipNewTab, buttonDeepLink = props.buttonDeepLink, buttonTermsItem = props.buttonTermsItem, disabled = props.disabled, labels = props.labels, onClick = props.onClick, points = props.points;
    if (buttonType !== 'terms' && !buttonLabel)
        return null;
    var handleDeepLinkClick = function () {
        var element = document.getElementById(buttonDeepLink.id.replace(/\s/g, '-'));
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
    };
    return {
        label: buttonType !== 'terms' ? buttonLabel : labels.PARTAKE_CONDITIONS,
        to: buttonType === 'terms'
            ? getTermsLink(buttonTermsItem)
            : buttonType === 'external'
                ? buttonExternalLink
                : buttonDeepLink
                    ? getDeepModuleLink(buttonDeepLink)
                    : buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.fullSlug,
        isPopup: buttonType !== 'external' && ((_a = buttonLink === null || buttonLink === void 0 ? void 0 : buttonLink.pageTemplate) === null || _a === void 0 ? void 0 : _a.startsWith('popup')),
        disabled: disabled,
        onClick: buttonDeepLink ? handleDeepLinkClick : onClick,
        points: points,
        skipNewTab: buttonExternalLinkSkipNewTab,
    };
}
