var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MenuItem from '@mui/material/MenuItem';
import MuiTextField from '@mui/material/TextField';
import { find, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useApi } from 'shared/api/ApiContext';
import Typography from 'shared/components/Typography';
import { useAppState } from 'shared/core/AppStateProvider';
import fieldToMuiProps from './fieldToMuiProps';
export default function CigaretteSelection(props) {
    var _this = this;
    var _a, _b;
    var label = props.label, rest = __rest(props, ["label"]);
    var api = useApi();
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useState(''), selectedCategory = _d[0], setSelectedCategory = _d[1];
    var _e = useState(''), selectedBrand = _e[0], setSelectedBrand = _e[1];
    var platformLabels = useAppState().labels;
    var muiProps = fieldToMuiProps(rest);
    var error = muiProps.error, helperText = muiProps.helperText;
    useEffect(function () {
        var loadOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, results, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = api.connector.get('/@/market-products').request;
                        return [4 /*yield*/, request];
                    case 1:
                        results = _a.sent();
                        setOptions(results);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadOptions();
    }, [api]);
    if (!selectedCategory && !selectedBrand && ((_a = props.field.value) === null || _a === void 0 ? void 0 : _a[0])) {
        // find cigarette in data array, set brand and category accordingly
        for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
            var cat = options_1[_i];
            for (var _f = 0, _g = cat.options; _f < _g.length; _f++) {
                var brand = _g[_f];
                if (find(brand.options, { _id: (_b = props.field.value) === null || _b === void 0 ? void 0 : _b[0] })) {
                    setSelectedBrand(brand.name);
                    setSelectedCategory(cat.name);
                }
            }
        }
    }
    var resetCigaretteProduct = function () {
        props.form.setFieldValue(muiProps.name, '');
    };
    var categories = map(options, 'name');
    var brands = [], products = [];
    if (selectedCategory) {
        // find option for category
        var categoryOptions = find(options, { name: selectedCategory });
        if (categoryOptions) {
            categoryOptions = (categoryOptions === null || categoryOptions === void 0 ? void 0 : categoryOptions.options) || [];
            brands = map(categoryOptions, 'name');
            if (selectedBrand) {
                var brandOptions = find(categoryOptions, { name: selectedBrand });
                if (brandOptions) {
                    brandOptions = (brandOptions === null || brandOptions === void 0 ? void 0 : brandOptions.options) || [];
                    products = map(brandOptions, function (o) {
                        return { label: o.name, value: o._id };
                    });
                }
                else {
                    setSelectedBrand('');
                }
            }
        }
        else {
            setSelectedBrand('');
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", noMargin: true, style: { marginTop: '4rem' }, children: label }), _jsx(MuiTextField, { label: platformLabels.SELECT_CIGARETTE_CATEGORY, select: true, value: selectedCategory, onChange: function (e) {
                    // console.log('value', e.target.value)
                    setSelectedCategory(e.target.value);
                    resetCigaretteProduct();
                }, required: rest.required, error: !selectedCategory && error, helperText: !selectedCategory && helperText, children: categories.map(function (o, i) { return (_jsx(MenuItem, { value: o, children: platformLabels["CIGARETTES-".concat(o.toUpperCase())] || o }, i)); }) }), selectedCategory && brands.length && (_jsx(MuiTextField, { label: platformLabels.SELECT_CIGARETTE_BRAND, select: true, value: selectedBrand, onChange: function (e) {
                    setSelectedBrand(e.target.value);
                    resetCigaretteProduct();
                }, required: rest.required, error: !selectedBrand && error, helperText: !selectedBrand && helperText, children: brands.map(function (o, i) { return (_jsx(MenuItem, { value: o, children: o }, i)); }) })), selectedBrand && products.length && (_jsx(MuiTextField, __assign({ label: platformLabels.SELECT_CIGARETTE_PRODUCT, select: true }, muiProps, { children: products.map(function (o, i) { return (_jsx(MenuItem, { value: o.value, children: o.label }, i)); }) })))] }));
}
